<template>
    <div class="units-row-container row">
        <div class="row-header column">
            <div>{{withCapitalLetter($t("units"))}}</div>
            <div>{{withCapitalLetter($t("lessons"))}}</div>
            <div>{{withCapitalLetter($t("scores"))}}</div>
        </div>
        <div class="row-body row">
            <student-unit-performance v-for="(unit, index) in units"
                                        :key="index"
                                        :unit="unit"/>
        </div>
    </div>
</template>

<script>

import StudentUnitPerformance from "./StudentUnitPerformance.vue";
import StringUtils from "@/utils/StringUtils.js";

export default {
    name: "UnitsRow",
    components: {
        StudentUnitPerformance,
        StringUtils
    },
    props: {
        units: Array
    },
    methods: {
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    }
}

</script>

<style lang="less" scoped>

.units-row-container{

    .row-header{
        width: 100px;
        margin-left: 24px;

        div{
            height: 56px;
            line-height: 56px;
        }

        div:first-child{
            color: #0A1554;
            font-family: Montserrat;
            font-size: 19.64px;
            font-weight: bold;
            letter-spacing: 0.25px;
        }

        div:nth-child(2), div:last-child{
            color: rgba(0,0,0,0.6);
            font-family: Roboto;
            font-size: 14px;
            font-weight: 300;
            letter-spacing: 0.25px;
        }
    }

    & /deep/ .student-unit-performance-container{
        margin-left: 16px;
    }

}

</style>