<template>
    <div class="venn-diagram-section column">
        <div class="diagram-directions column">
            <div class="row">
                <AudioToggle v-if="question.audio" :audio="question.audio"/>
                <span>{{$t('compareTag')}}</span> 
            </div>
            <div class="row sets">
                <div class="set column">
                    <img class="set-image" v-if="question.primarySetImage" :src="getImageUrl(question.primarySetImage.name)">
                    <span>{{question.primarySetContent}}</span>
                </div>
                <p class="custom-scrollbar" v-html="question.question"></p>
                <div class="set column">
                    <img class="set-image" v-if="question.secondarySetImage" :src="getImageUrl(question.secondarySetImage.name)">
                    <span>{{question.secondarySetContent}}</span>
                </div>
            </div>
        </div>
        <div class="sets-container row">
            <VennDiagramSet 
                :question="question" 
                :options="{set: 'primary-set', name: question.primarySetContent}"
                :isFeedback="feedbackQuestion != null"/>
            <VennDiagramSet 
                :question="question" 
                :options="{set: 'intersection', name: this.$t('both')}"
                :isFeedback="feedbackQuestion != null"/>
            <VennDiagramSet 
                :question="question" 
                :options="{set: 'secondary-set', name: question.secondarySetContent}"
                :isFeedback="feedbackQuestion != null"/>
        </div>
        <VennDiagramBank v-if="!feedbackQuestion" :question="question" :options="{set: 'empty'}"/>
    </div>
</template>
<script>
import VennDiagramBank from '@/components/assessment/VennDiagramBank.vue'
import VennDiagramSet from '@/components/assessment/VennDiagramSet.vue'
import AudioToggle from '@/common/AudioToggle.vue'
export default {
    components:{
        VennDiagramBank,
        VennDiagramSet, 
        AudioToggle
    },
    props:{
        feedbackQuestion: {
            type: Object,
            required: false,
            default: null
        }
    },
    computed:{
        question(){
            if(this.feedbackQuestion)
                return this.feedbackQuestion.vennDiagram;

            return this.$store.state.assessment
                .questions.find(e => e.type==='venn-diagram');
        }
    },
    methods:{
        addElement(){
            this.$store.commit('addVennDiagramElement')
        }
    }
}
</script>
<style lang="less" scoped>
.venn-diagram-section{
    width: 920px;
    margin: 16px auto;
    color: #0A1554;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 600;

    .diagram-directions{
        padding: 16px;
        box-sizing: border-box;
        height: 230px;
        width: 920px;
        background-color: rgba(201,207,255,0.2);

        span{
            margin: auto;
        }

        .sets{
            margin-top: 24px;
            p{
                max-width: 430px;
                max-height: 150px;
                margin: 0 37px;
                font-style: italic;
                font-weight: 400;
                font-size: 18px;
                letter-spacing: 0.5px;
                line-height: 28px;
            }
            .column{
                min-width: 147px;
                max-width: 147px;
                min-height: 147px;
            }
            .column:first-of-type{
                margin-left: 64px;
            }
            span{
                margin: auto;
            }
            .set:nth-child(3){
                margin-left: auto;
                margin-right: 41px;
            }
        }
        .set-image{
            max-height: 147px;
            max-width: 147px;
            height: auto;
            width: auto;
        }
    }

    .sets-container{
        justify-content: center;
    }

}
</style>

