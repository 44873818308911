import { render, staticRenderFns } from "./TopVisitedSectionsPerStudent.vue?vue&type=template&id=39db697c&scoped=true&"
import script from "./TopVisitedSectionsPerStudent.vue?vue&type=script&lang=js&"
export * from "./TopVisitedSectionsPerStudent.vue?vue&type=script&lang=js&"
import style0 from "./TopVisitedSectionsPerStudent.vue?vue&type=style&index=0&id=39db697c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39db697c",
  null
  
)

component.options.__file = "TopVisitedSectionsPerStudent.vue"
export default component.exports