<template>
    <div class="paginator-container row">
        <div class="previousArrow circle" 
            :class="[{disabled: !isTherePrevious}]" 
            v-on:click="previous"></div>
        <div class="rightArrow circle"
            :class="[{disabled: !isThereNext}]" 
             v-on:click="next"></div>
    </div>
</template>

<script>

export default {
    name: "Paginator",
    props: {
        collection: Array,
        itemsPerPage: {
            type: Number,
            required: false,
            default: 7
        },
        currentPage: {
            type: Number,
            required: false,
            default: 0
        }
    },
    data: function(){
        return {
            defaultLimit: 7,
            p_itemsPerPage: this.itemsPerPage,
            p_currentPage: this.currentPage
        };
    },
    computed: {
        amountOfPages(){
            let _amountOfPages = Math.floor(this.collection.length 
                                                    / this.p_itemsPerPage);

            return this.collection.length % this.p_itemsPerPage === 0 ? 
                                            _amountOfPages : _amountOfPages + 1;
        },
        isTherePrevious(){
            return this.p_currentPage > 0;
        },
        isThereNext(){
            return this.p_currentPage < this.amountOfPages - 1;
        }
    },
    methods: {
        previous(){
            if(!this.isTherePrevious)
                return;

            this.p_currentPage--;
            this.getPartialCollection();
        },
        next(){
            if(!this.isThereNext)
                return;

            this.p_currentPage++;
            this.getPartialCollection();
        },
        getPartialCollection(){
            this.partialCollection = this.collection.slice(this.p_currentPage * this.p_itemsPerPage, 
                                                            (this.p_currentPage + 1) * this.p_itemsPerPage);

            this.$emit("onPartialCollectionUpdate", this.partialCollection);
        }
    },
    watch: {
        currentPage(newValue){
            if(newValue < 0 || newValue >= this.amountOfPages)
                this.p_currentPage = 0;
            else
                this.p_currentPage = newValue;

            this.getPartialCollection();
        },
        itemsPerPage(newValue){
            if(newValue < 1 || newValue > this.collection.length)
                this.p_itemsPerPage = this.defaultLimit;
            else
                this.p_itemsPerPage = newValue;

            this.getPartialCollection();
        },
        collection(){
            if(this.p_currentPage >= this.amountOfPages)
                this.p_currentPage = this.amountOfPages > 0 ? this.amountOfPages - 1 : 0;

            this.getPartialCollection();
        }
    },
    mounted(){
        this.getPartialCollection();
    }
}

</script>

<style lang="less" scoped>

.paginator-container{
    width: 100%;
    height: 24px;

    .previousArrow, .rightArrow{
        background-color: #4356FD;
        font-size: 24px;
        line-height: 24px;

        &:hover{
            cursor: pointer;
        }

        &.disabled{
            background-color: #bbbcbf;
        }
    }

    .previousArrow{
    
        &::before{
            content: '';
            transform: rotate(45deg);
            min-height: 8px;
            min-width: 8px;
            background-color: unset;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            position: absolute;
            left: 5px;
            top:8px;
        }
        &::after{
            content: '';
            min-height: 8px;
            min-width: 12px;
            background-color: unset;
            border-bottom: 1px solid #000;
            position: absolute;
            left: 5px;
            top:4px;
        }
    }

    .rightArrow{
        margin-left: auto;
        margin-right: 0px;

        &::before{
            content: '';
            transform: rotate(45deg);
            min-height: 8px;
            min-width: 8px;
            background-color: unset;
            border-right: 1px solid #000;
            border-top: 1px solid #000;
            position: absolute;
            left: 9px;
            top:8px;
        }
        &::after{
            content: '';
            min-height: 8px;
            min-width: 12px;
            background-color: unset;
            border-bottom: 1px solid #000;
            position: absolute;
            left: 6px;
            top:4px;
        }
    }
}

</style>

