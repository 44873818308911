<template>
    <div class="venn-diagram-set-element row" :class="elementBackGroundStatus">
        <span>
            {{element.content}}
        </span>
    </div>
</template>
<script>
export default {
    name: 'VennDiagramSetElement',
    props: {
        element: Object,
        isFeedback: Boolean
    }, 
    computed:{ 
        isAssessmentSubmitted(){
            return this.$store.state.assessment.assessmentCompleted || this.isFeedback;
        }, 
        elementBackGroundStatus(){
            if(!this.isAssessmentSubmitted)
                return ''
            return this.element.studentAnswerSet != this.element.correctAnswerSet
                ? 'failed' : 'correct'
        }
    }

}
</script>
<style lang="less" scoped>
.venn-diagram-set-element{
    margin: auto;
    width: 162px;
    min-height: 34px;
    margin-top: 8px;
    text-align: center;
    background: #d8d8d8;
    color: #fff;
    border-radius: 20px;
    padding: 5px 16px;
    box-sizing: border-box;
    span{
        margin:auto;
        font-weight: 100;
        white-space: normal;
        text-overflow: initial;
        color: black;
    }

    cursor: -webkit-grab;
    &.ghost{
        cursor: -webkit-grabbing !important;
    }
    margin-bottom:auto;

    &.failed {
        background-color: #ff5252;
    }
    &.correct {
        background-color: #B8E986;
    }
}
</style>


