<template>
    <div class="venn-diagram-bank">
        <span>{{$t('bank')}}</span>
        <div class="bank-container">
            <draggable class="draggable-section" v-model="elements" :options="dragOptions"
                :move="onMove" @change="onChange">
                <transition-group name="flip-list">
                    <VennDiagramSetElement v-for="(element, index) in elements" :key="index"
                        :element="element"/>   
                </transition-group>
            </draggable>
        </div>
    </div>
</template>
<script>
import VennDiagramDraggableMixin from '@/components/assessment/VennDiagramDraggableMixin.js'
export default {
    name: 'VennDiagramBank',
    mixins:[VennDiagramDraggableMixin], 
}
</script>
<style lang="less" scoped>
.venn-diagram-bank{
    margin-top: 10px;
    >span{
        margin-left: 100px;
    }
    .bank-container{
        width: 720px;
        min-height: 120px;
        margin: auto;
        margin-top: 8px;
        padding: 16px;
        box-sizing: border-box;
        background-color: rgba(201,207,255,0.2);
        .draggable-section{
            min-height: unset;
            span{
                min-height: 100px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
    }
}
</style>
