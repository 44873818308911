import draggable from 'vuedraggable'
import VennDiagramSetElement from '@/components/assessment/VennDiagramSetElement.vue'
export default{
    components:{
        draggable, 
        VennDiagramSetElement
    },
    props:{
        options: Object, 
        question: Object,
        isFeedback: Boolean
    },
    data(){
        return{
            isDragging: false,
            delayedDragging: false
        }
    },
    methods:{        
        onMove({relatedContext, draggedContext}) {
            const relatedElement = relatedContext.element;
            const draggedElement = draggedContext.element;
            return (
                (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
            );
        }, 
        onChange({added}){
            if(this.$store.state.isPreviewModeActive 
                || this.isAssessmentSubmitted)
                return
                
            if(added){
                this.$store.dispatch('setVennDiagramStudentAnswer',{
                    assessmentId: this.$store.state.assessment.id,
                    questionId: this.question.id,
                    venDiagramSet: this.options.set,
                    venDiagramElementId: added.element.id
                })
                .then(()=>{
                    let element = this.question.vennDiagramElements
                        .find(e => e.id === added.element.id)
                    this.$store.commit('updateVennDiagramElement', {
                        element: element, 
                        set: this.options.set
                    })
                })
            }
        }
    },
    computed: {
        dragOptions(){    
            return {
                animation: 0,
                group: "elements",
                ghostClass: "ghost"
            }
        }, 
        elements:{
            get(){
                return this.question.vennDiagramElements
                    .filter(e => e.studentAnswerSet === this.options.set)
            }, 
            set(){
            }
        }, 
        isAssessmentSubmitted(){
            return this.$store.state.assessment.assessmentCompleted || this.isFeedback;
        }
    },
    watch: {
      isDragging(newValue) {
        if (newValue) {
          this.delayedDragging = true;
          return;
        }
        this.$nextTick(() => {
          this.delayedDragging = false;
        });
      }
    }
}