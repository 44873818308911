<template>
    <div class="student-performance-container column">
        <div class="student-performance-body">
            <div v-if="unitsGroups.length > 0">
                <units-row v-for="(group, index) in unitsGroups"
                            :key="index"
                            :units="group" />
            </div>
            <div v-else class="no-content row">
                <span>{{$t("noContent")}}</span>
            </div>
        </div>
    </div>
</template>

<script>

import ReportsService from "@/services/ReportsService.js";
import UnitsRow from "@/components/BackOffice/Reports/StudentPerformance/UnitsRow.vue";

export default {
    name: "StudentPerformance",
    components: {
        UnitsRow
    },
    computed: {
        studentPerformance(){
            return this.$store.state.studentPerformance;
        },
        unitsGroups(){
            return ReportsService.getUnitsGroups(this.studentPerformance); 
        }
    },
    mounted(){
        this.isLoadingInformation = true;
        this.$store.dispatch("getStudentPerformance", this.$route.params.studentId)
            .finally(()=>{
                this.isLoadingInformation = false;
            })
        this.$appInsights.startTrackPage("Student Performance");
    },
    beforeDestroy(){
        this.$appInsights.stopTrackPage("Student Performance");
    }
}

</script>

<style lang="less" scoped>

.student-performance-container{
    width: 704px;
    .student-performance-body{
        .no-content{
            min-height: 300px;
            align-items: center;
            justify-content: center;
        }
    }
}

</style>