<template>
    <div class="lesson-item column">
        <div class="row">
            <label>{{lesson.number}} {{lesson.title}}</label> <span>{{$t('score')}}: {{score}}</span>
            <button class="btn primary" @click="previewLesson">{{$t('preview')}}</button>
            <button class="btn secondary" @click="retakeAssessment">retake</button>
        </div>
        <span class="question-link" v-if="lesson.multipleChoices" @click="showMultipleChoiceFeedbackModal = true">{{$t('multipleChoiceLongName')}}</span>
        <span class="question-link" v-if="lesson.vennDiagram" @click="showVenDiagramFeedbackModal = true">{{$t('vennDiagramLongName')}}</span>
        <span class="question-link" v-if="lesson.writtenAnswers" @click="showWrittenAnswerFeedbackModal = true">{{$t('writtenLongName')}}</span>
        <span class="question-link" v-if="lesson.essay" @click="showEssayFeedbackModal = true">{{$t('essayLongName')}}</span>
        <modal v-if="showEssayFeedbackModal" :click-handler="updateEssayFeedBack"
            text-button="SEND"  @close="showEssayFeedbackModal = false">
            <answer-feed-back :lesson="lesson" :key="`${studentId} - essay: ${lesson.essay.id}`" :question="lesson.essay" />
        </modal>

        <modal v-if="showWrittenAnswerFeedbackModal" :click-handler="updateWrittenAnswersFeedBack"
            text-button="SEND"  @close="showWrittenAnswerFeedbackModal = false">
            <div class="questions-container column custom-scrollbar">
                <answer-feed-back v-for="question in lesson.writtenAnswers" :key="`${studentId} - written question: ${question.id}`"
                :lesson="lesson" :question="question" />
            </div>
        </modal>

        <modal v-if="showMultipleChoiceFeedbackModal" 
            :click-handler="hideModal"
            :showCancelButton="false"
            :text-button="$t('ok')">
            <div class="questions-container column custom-scrollbar">
                <multiple-choice-feedback v-for="question in lesson.multipleChoices" 
                                        :key="`${studentId} - written question: ${question.id}`"
                                        :lesson="lesson"
                                        :question="question" />
            </div>
        </modal>

        <modal v-if="showVenDiagramFeedbackModal" 
            :click-handler="hideModal"
            :showCancelButton="false"
            :text-button="$t('ok')">
            <venn-diagram-section :feedbackQuestion="lesson"/>
        </modal>
    </div>
</template>
<script>
import Modal from '@/common/Modal.vue';
import AnswerFeedBack from './AnswerFeedBack.vue';
import MultipleChoiceFeedback from "./MultipleChoiceFeedback.vue";
import VennDiagramSection from "@/components/assessment/VennDiagramSection.vue";
import AssessmentScoreMixin from '@/common/AssessmentScoreMixin.js';

export default {
    name: 'LessonListItem',
    mixins:[AssessmentScoreMixin],
    props:{
        lesson: Object, 
        unit: Object
    }, 
    components:{
        Modal, 
        AnswerFeedBack,
        MultipleChoiceFeedback,
        VennDiagramSection, 
    },
    data: function(){
        return{
            showEssayFeedbackModal: false,
            showWrittenAnswerFeedbackModal: false,
            showMultipleChoiceFeedbackModal: false,
            showVenDiagramFeedbackModal: false
        }
    }, 
    computed:{
        studentId(){
            return this.$store.state.selectedStudentForFeedBack.id
        }, 
        score(){
            return this.getAssessmentScore()
        }
    },
    methods:{
        updateEssayFeedBack(){
            let essayAnswer = [this.lesson.essay]
            this.updateAnswers(essayAnswer)
            this.showEssayFeedbackModal= false
        },
        updateWrittenAnswersFeedBack(){
            this.updateAnswers(this.lesson.writtenAnswers)
            this.showWrittenAnswerFeedbackModal= false
        }, 
        updateAnswers(answers){
            return this.$store.dispatch('setTeacherFeedBack', answers)
        }, 
        retakeAssessment(){
            this.$store.dispatch('retakeAssessment',{
                lessonId: this.lesson.id, 
                studentId:  this.$store.state.selectedStudentForFeedBack.id
            })
                .then(()=>{
                    this.$store.commit('removeLessonFromFeedback',{
                        unit: this.unit, 
                        lesson: this.lesson
                    })
                })
        },
        hideModal(){
            this.showMultipleChoiceFeedbackModal = false;
            this.showVenDiagramFeedbackModal = false;
        }, 
        getAssessmentScore(){
            let answers =[]
            if(this.lesson.multipleChoices)
                answers =[...this.lesson.multipleChoices]

            if(this.lesson.writtenAnswers)
                answers =[...answers, ...this.lesson.writtenAnswers]

            if(this.lesson.vennDiagram)
                answers.push(this.lesson.vennDiagram)
            if(this.lesson.essay)
                answers.push(this.lesson.essay)

            let score = this.getScore(answers)
            let correctAnswers = this.getCorrectAnswers(answers)
            return `${score}% (${correctAnswers}/${answers.length})`
        }, 
        previewLesson(){
            this.$router.push({
                name: 'LessonBuilderPreviewMode', 
                params:{
                    lessonId: this.lesson.id, 
                    unitId: this.unit.id
                }, 
                query:{                    
                    ...this.$route.params, 
                }
            })
        }
    }, 
    mounted(){
        
    }
}
</script>
<style lang="less" scoped>
.lesson-item{

    label,span{
        height: 28px;
        color: rgba(0,0,0,0.6);
        font-family: 'Roboto', sans-serif;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 28px;
    }
    label{
        font-weight: bold;
        margin-left: 48px;
    }
    span{
        text-transform: capitalize;
        margin-left: 16px;
    }

    .question-link{
         color: #3A4DFD;
         font-style: italic;
         cursor: pointer;
         margin-left: 64px;
         text-decoration: underline;
     }

    > .row{
        .btn:first-of-type{
            margin-left: auto;
            margin-right: 16px;
        }
    }

    .questions-container{
        max-height: 550px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    :global(.modal-container) {
        max-width: 960px;
    }
}
</style>

